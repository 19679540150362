import { HttpClient, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { Params } from '@angular/router';

import { API } from '@core/constants';
import {
	PlaceAd,
	EditAdResponseData,
	ReOrderImage,
	Ad,
	PlaceAdPaymentParams,
	PaymentResponse,
	VerifyPayment,
	PlanTiers,
	Coupon,
	VerifyCoupon,
	Brochure,
	Ericode,
	Agent,
	MobileLinks,
} from '@core/models';
import { CommonUtil } from '@core/utils/common.util';
import { environment } from '@environments/environment';

@Injectable({
	providedIn: 'root',
})
export class PlaceAdApi {
	constructor(private http: HttpClient) {}

	uploadImage(body: FormData, id: string): Observable<HttpEvent<Object>> {
		return this.http.post(API.placeAd.imageUpload(id), body, {
			observe: 'events',
			reportProgress: true,
		});
	}

	deleteImage(id: string, imageId: string): Observable<{}> {
		return this.http.delete(API.placeAd.deleteImage(id, imageId));
	}

	createAd(body: PlaceAd): Observable<{ id: string }> {
		return this.http.post<{ data: { id: string } }>(API.placeAd.base, body).pipe(map((response) => response.data));
	}

	updateAd(id: string, body: PlaceAd): Observable<{ id: string }> {
		return this.http.patch<{ data: { id: string } }>(API.placeAd.updateAd(id), body).pipe(map((response) => response.data));
	}

	//TODO:to chekck with API team regardign this API
	completeAd(id: string, body: PlaceAd): Observable<{}> {
		return this.http.patch<{}>(API.placeAd.completeAd(id), body);
	}

	editAd(id: string): Observable<EditAdResponseData> {
		return this.http.get(API.placeAd.editAd(id)).pipe(map((response: { data: EditAdResponseData }) => response?.data));
	}

	getAd(id: string): Observable<Ad> {
		return this.http.get<{ data: Ad }>(API.placeAd.getAd(id)).pipe(map((response) => response?.data));
	}

	reOrderImage(adId: string, images: ReOrderImage[]): Observable<{}> {
		return this.http.patch<{}>(API.placeAd.reOrderImage(adId), { image: images });
	}

	getPlanTiers(params: Params): Observable<PlanTiers> {
		return this.http
			.get<{ data: PlanTiers }>(API.placeAd.planTiers, {
				params: CommonUtil.createHttpParams(params),
			})
			.pipe(map((response) => response?.data));
	}

	//to initialize stripe payment
	stripePayment(body: PlaceAdPaymentParams): Observable<PaymentResponse> {
		return this.http.post<{ data: PaymentResponse }>(API.placeAd.placeAdPaymentInitialization, body).pipe(map((response) => response?.data));
	}

	//to verify payment
	completePayment(body: VerifyPayment): Observable<string> {
		return this.http.post<string>(API.placeAd.paymentComplete, body);
	}

	couponPayment(body: Coupon): Observable<{ adId: string }> {
		return this.http.post<{ data: { adId: string } }>(API.placeAd.couponPayment, body).pipe(map((response) => response?.data));
	}

	verifyCoupon(body: Coupon): Observable<VerifyCoupon> {
		return this.http.post<{ data: VerifyCoupon }>(API.placeAd.verifyCoupon, body).pipe(map((response) => response?.data));
	}

	uploadBrochure(adId: string, body: FormData): Observable<Brochure> {
		return this.http.post<{ data: Brochure }>(API.placeAd.updateBrochure(adId), body).pipe(map((response) => response?.data));
	}

	deleteBrochure(adId): Observable<{}> {
		return this.http.delete(API.placeAd.updateBrochure(adId));
	}

	uploadAdImageAfterRotate(adId: string, imageId: string, formData: FormData): Observable<{}> {
		return this.http.post(API.placeAd.rotatedImage(adId, imageId), formData).pipe(map((response) => response?.['data']));
	}

	getEriCode(searchKeyword: string, params: string): Observable<Ericode> {
		return this.http
			.get<{ data: Ericode }>(API.placeAd.getEricode(searchKeyword), {
				params: {
					isForceUpdate: params,
				},
			})
			.pipe(map((response) => response?.data));
	}

	getLastAdDetails(): Observable<Ad> {
		return this.http
			.get<{ data: Ad }>(API.placeAd.lastAdDetails)

			.pipe(map((response) => response?.data));
	}

	getMobileAppRedirectLink({ adId, sessionId }: VerifyPayment): Observable<MobileLinks> {
		return this.http.post<MobileLinks>(API.placeAd.mobileAppRedirect, {
			dynamicLinkInfo: {
				domainUriPrefix: 'https://findqo.page.link',
				link: `${environment.appUrlForDeeplink}?adId=${adId}&sessionId=${sessionId}`,
				androidInfo: {
					androidPackageName: 'com.findqo.app',
				},
				iosInfo: {
					iosBundleId: 'com.findqo.app',
				},
			},
		});
	}
}
